import { template as template_4fe78a3a1ec34ac2a21c2e368ddea11d } from "@ember/template-compiler";
const FKLabel = template_4fe78a3a1ec34ac2a21c2e368ddea11d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
