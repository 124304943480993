import { template as template_ce746f6d213d4d23b7be5db6794d0eb2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ce746f6d213d4d23b7be5db6794d0eb2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
